/* eslint-disable consistent-return */
import React, { useEffect } from 'react';
import '../../stylesheets/app.scss';
import { NavigationBarAndMenu, Footer } from '@library/shared-ui';
import { initZendeskWidget } from './zendesk';
import { ScreenSizeConfigurator } from '../../../../../library/shared-ui/components/ScreenSizeConfigurator';

const SearchUiSuggestions = React.lazy(() => import('../SearchUiSuggestions'));

const Layout = ({ children, data }) => {
  const schema = React.useMemo(
    () =>
      children?.props?.children?.props?.children?.props?.data ||
      children?.props?.data ||
      data,
    [
      children?.props?.children?.props?.children?.props?.data,
      children?.props?.data,
      data,
    ]
  );
  const locales = React.useMemo(
    () =>
      children?.props?.children?.props?.children?.props ||
      children?.props ||
      data,
    [children?.props?.children?.props?.children?.props, children?.props, data]
  );

  const isBuilderPage = !!locales?.pageContext?.isBuilderPage;

  const navbarMenuSection = isBuilderPage
    ? schema?.contentfulNavigationBarAndMenu
    : schema?.contentfulPageTemplate?.navbarMenuSection;

  const footer = isBuilderPage
    ? schema?.contentfulFooter
    : schema?.contentfulPageTemplate?.footerSection;

  useEffect(() => {
    initZendeskWidget(process.env.GATSBY_ZENDESK_KEY);
  }, []);

  return (
    <ScreenSizeConfigurator>
      {!!navbarMenuSection && (
        <NavigationBarAndMenu
          {...navbarMenuSection}
          showSearchSuggestions
          searchSuggestions={<SearchUiSuggestions />}
          mobileButton={undefined}
        />
      )}
      <main>{children}</main>
      {!!footer && (
        <Footer
          {...footer}
          pageContext={locales?.pageContext || locales?.allContentfulLocale}
          analyticServices={{
            fullstory: true,
            gtm: true,
            vwo: true,
            apollo: true,
          }}
        />
      )}
    </ScreenSizeConfigurator>
  );
};

export default Layout;
